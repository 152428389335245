import { Widget } from '@typeform/embed-react'

const Noodl = require('@noodl/noodl-sdk');

function MyCustomReactComponent(props) {
	const style = {
		width: '100%', 
		height: '100%', 
		marginBottom: props.marginBottom
	};
	const params = {
		user_name: props.userName, 
		company_name: props.companyName
	}
	return <Widget
          id={props.formId}
          style={style}
					hidden={params}
					enableSandbox={props.sandbox}		
					inlineOnMobile='true'
					keepSession='true'
					onReady={props.ready}		
					onQuestionChanged={props.onQuestionChanged}
					onEndingButtonClick={props.done}
					onSubmit={props.onSubmit}
        />
}

const MyCustomReactComponentNode = Noodl.defineReactNode({
	name: 'Typerform Embed Component',
	category: 'SeedCulture',
	initialize() {
		this.props.onQuestionChanged = ({formId, ref}) => {
			this.outputs.currentQuestionRef = ref;
			this.flagOutputDirty('currentQuestionRef');
			this.sendSignalOnOutput('questionChanged');
		};
		this.props.onSubmit = ({formId, responseId}) => {
			this.outputs.formResponseId = responseId;
			this.flagOutputDirty('formResponseId');
			this.sendSignalOnOutput('submitted');
		};
	}, 
	getReactComponent() {
		return MyCustomReactComponent;
	},
	
	inputProps: {
		formId: {
      displayName: 'Form ID',
      type: 'string'
    }, 
		userName: {
      displayName: 'User Name',
      type: 'string'
    }, 
		companyName: {
      displayName: 'Company Name',
      type: 'string'
    }, 
		sandbox: {
			displayName: 'Sandbox', 
			type: 'boolean'
		}, 
		marginBottom: {
			type: {
				name: 'number', 
				units: ['px'], 
				defaultUnit: 'px'
			}, 
			default: 10
		}
	},
	outputProps: {
		ready: {type: 'signal', displayName: 'Ready'}, 
		questionChanged: {type: 'signal', displayName: 'Question Changed'}, 
		submitted: {type: 'signal', displayName: 'Response Submitted'}, 
		done: {type: 'signal', displayName: 'Done'}
	}, 
	outputs: {
		formResponseId: {type: 'string', displayName: 'Form Response ID'}, 
		currentQuestionRef: {type: 'string', displayName: 'Current Question Ref'}
	}
})


Noodl.defineModule({ 
    reactNodes: [
    	MyCustomReactComponentNode
    ],
    nodes:[
    ],
    setup() {
    	//this is called once on startup
    }
});